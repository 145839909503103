import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import router from "./router";
import store from "./store";
import App from "./App.vue";
import i18n from "@/libs/i18n";
import FlagIcon from "vue-flag-icon";
// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

import * as VueGoogleMaps from "vue2-google-maps";

require("./firebase/deviceToken");

require("./firebase/messaging");

// require('./firebase/onBackgroundMessage');

// Helper Composable
import helpers from "@/composables/helper";

Vue.use({
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
});
Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyBfNAqiKmryoUvnp4lnxKEtpjs7fUgji88',
    key: "AIzaSyBzxBhHeLGqcQFNmdqcZv-kpkkuXqF53Kk",
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
});
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(FlagIcon);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;
Vue.mixin({
  computed: {
    globalPermission() {
      return store.getters.getUserPermission;
    },
  },
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
