export default [
    {
      path: "/users-list",
      name: "users",
      component: () => import("@/views/users/usersList/index.vue"),
    },
    {
      path: '/add-user',
      name: 'addUser',
      component: () => import('@/views/users/addUser/index.vue'),
      meta: {
          pageTitle: 'users.add_user',
          breadcrumb: [
              {
                  text: 'users.add_user',
                  active: true
              },
          ],
      },
  },
  {
    path: '/edit-user/:id',
    name: 'editUser',
    component: () => import('@/views/users/addUser/index.vue'),
    meta: {
        pageTitle: 'users.edit_user',
        breadcrumb: [
            {
                text: 'users.edit_user',
                active: true
            },
        ],
    },
}
];
  