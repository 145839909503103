export default [
  {
    path: "/catalog/add-catalog",
    name: "AddCatalog",
    component: () => import("@/views/catalogs/addCatalog/index.vue"),
    meta: {
      pageTitle: "g.add_catalog",
      permission: {
        catalogs: { store: true },
        brands: { index: true },
        categories: { index: true },
      },
      parentPage: "g.catalog",
      breadcrumb: [
        {
          text: "g.add_catalog",
          active: true,
        },
      ],
    },
  },
  {
    path: "/catalog/show-catalog/:id",
    name: "ShowCatalog",
    component: () => import("@/views/catalogs/addCatalog/index.vue"),
    meta: {
      pageTitle: "g.show_catalog",
      parentPage: "g.catalog",
      permission: {
        catalogs: { show: true },
        brands: { index: true },
        categories: { index: true },
      },

      breadcrumb: [
        {
          text: "g.show_catalog",
          active: true,
        },
      ],
    },
  },
  {
    path: "/catalog/request-catalog/:id",
    name: "RequestCatalog",
    component: () => import("@/views/catalogs/addCatalog/index.vue"),
    meta: {
      pageTitle: "g.request_catalog",
      parentPage: "g.catalog",
      permission: {
        catalogs: { store: true },
        brands: { index: true },
        categories: { index: true },
      },

      breadcrumb: [
        {
          text: "g.request_catalog",
          active: true,
        },
      ],
    },
  },
  {
    path: "/catalog-list",
    name: "CatalogList",
    component: () => import("@/views/catalogs/listCatalogs/index.vue"),
    meta: {
      pageTitle: "g.catalog_list",
      parentPage: "g.catalog",
      permission: {
        catalogs: { index: true },
        brands: { index: true },
        categories: { index: true },
      },
      breadcrumb: [
        {
          text: "g.catalog_list",
          active: true,
        },
      ],
    },
  },
];
