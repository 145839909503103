export default [
  {
    path: "/add-product",
    name: "add-product",
    meta: {
      permission: { catalogs: { store: true } },
    },
    component: () => import("@/views/products/addProduct/index.vue"),
  },
  {
    path: "/show-product/:catalogSlug/:slug",
    name: "show-product",
    meta: {
      permission: { catalogs: { show: true } },
    },

    component: () => import("@/views/products/singleProduct/show/index.vue"),
  },
  {
    path: "/product-review/:slug",
    name: "product-review",
    meta: {
      permission: { reviews: { index: true } },
    },
    component: () => import("@/views/products/singleProduct/reviews/index"),
  },
  {
    path: "/products-list",
    name: "products",
    meta: {
      permission: { catalogs: { index: true }, products: { index: true } },
    },
    component: () => import("@/views/products/productsList/index.vue"),
  },
];
