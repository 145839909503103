import useJwt from "@/auth/jwt/useJwt";
import Store from "@/store";
import axios from "@/libs/axios";
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    (localStorage.getItem("userData") || Store?.state?.userData) &&
    localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  );
};

/**
 * Checks if the user has vendor data in local storage and returns the parsed value.
 *
 * @return {boolean} The parsed value of the "vendorData" item in local storage.
 */
export const isUserVendorData = () => {
  return !!(
    JSON.parse(localStorage.getItem("myVendorData")) ||
    Store?.state?.userData?.userData
  );
};

/**
 * Retrieves the approval status of a user's vendor account.
 *
 * @return {boolean} The approval status of the user's vendor account.
 */
export const isUserVendorIsApproved = () => {
  return !!(
    JSON.parse(localStorage.getItem("isVendor")) ||
    Store?.state?.userData?.is_approved
  );
};

export const getUserData = () =>
  JSON.parse(localStorage.getItem("myVendorData"));

export const getVendorRoles = () =>
  JSON.parse(localStorage.getItem("myVendorData"))?.roles?.filter(
    (ele) => ele.guard_name === "vendor"
  )[0]?.permissions;
/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "Super Admin") return "/";
  return { name: "login" };
};

export const getRoutePermissionCheck = (
  requiredPermission,
  wholePermissionData
) => {
  if (!requiredPermission) {
    return true;
  }

  const check = Object.keys(requiredPermission).map((permission) => {
    const subPermission = Object.keys(requiredPermission[permission])[0];
    return wholePermissionData[permission][subPermission];
  });

  return check.every((ele) => ele);
};

export const handelPermission = (permissionsWholeData) => {
  const newPermissionsObj = {};
  if (permissionsWholeData) {
    permissionsWholeData.forEach((singlePermissionObj) => {
      const singlePermissionSubPermissions = {};
      singlePermissionObj.subPermissions.forEach((supPermissionElement) => {
        singlePermissionSubPermissions[supPermissionElement.name] =
          supPermissionElement.is_checked;
      });

      newPermissionsObj[singlePermissionObj.name] = {
        ...singlePermissionSubPermissions,
        ...newPermissionsObj[singlePermissionObj.name],
      };
    });
  }
  return newPermissionsObj;
};

export const isUnderMaintenance = async () => {
  try {
    const res = await axios.get("web/settings", {
      params: {
        key: "in_maintenance_vendor",
      },
    });
    return res.data?.data?.value?.value === "1";
  } catch (error) {}
};
