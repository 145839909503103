import Swal from "sweetalert2";
import i18n from "@/libs/i18n";
import toast from "@/libs/toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import builtInFunctions from "@/composables/builtInFunctions";
import formatDate from "@/composables/format-date";

export default {
  ...builtInFunctions,

  ...formatDate,

  makeToast(variant = "success", title, body) {
    toast({
      component: ToastificationContent,
      position: "top-right",
      props: {
        title: i18n.t(title),
        icon:
          variant === "success"
            ? "CheckCircleIcon"
            : variant === "danger"
            ? "XCircleIcon"
            : "AlertTriangleIcon",
        variant: variant,
        text: i18n.t(body),
      },
    });
  },

  handleError(error) {
    const errorType = error?.response?.status === 463 ? "warning" : "danger";
    const errorMessageTitle =
      error?.response?.status === 463
        ? i18n.t("g.alertS")
        : i18n.t("g.errorTitle");
    if (error.response.data.message && !error.response.data.errors) {
      this.makeToast(errorType, errorMessageTitle, error.response.data.message);
    } else if (error.response.data.errors) {
      for (const key in error.response.data.errors) {
        this.makeToast(
          errorType,
          errorMessageTitle,
          error.response.data.errors[key][0]
        );
      }
    } else if (error.response.data.data.message) {
      this.makeToast(
        errorType,
        errorMessageTitle,
        error.response.data.data.message
      );
    } else {
      this.makeToast(
        errorType,
        i18n.t("g.errorTitle"),
        i18n.t("g.errorMessage")
      );
    }
  },

  filterImages(type, data = null, username = null, defaultImage = null) {
    if (data && this.isset(data)) {
      if (this.isArray(data)) {
        for (let image of data) {
          if (image.name === type) {
            return image.path;
          }
        }
      }
      return data.path;
    }
    return defaultImage ? defaultImage : this.uiAvatar(username);
  },

  sweetAlertConfirm(
    title,
    text,
    icon,
    confirmButtonText,
    cancelButtonText,
    callback
  ) {
    Swal.fire({
      title,
      text,
      icon,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText,
      cancelButtonText,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ml-1",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
        this.makeToast(
          "success",
          i18n.t("g.successTitle"),
          i18n.t("g.successMessage")
        );
      }
    });
  },

  slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  },

  uiAvatar(name) {
    return `https://ui-avatars.com/api/?name=${this.slugify(
      name
    )}&background=random`;
  },

  dateTimeConvertFunction(time) {
    const timestampDate = new Date(time);
    const currentDate = new Date();
    const timeDifference = currentDate - timestampDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  },
};
