import Vue from "vue";
import VueRouter from "vue-router";
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
  isUserVendorData,
  isUserVendorIsApproved,
  getPermissionCheck,
  getVendorRoles,
  handelPermission,
  getRoutePermissionCheck,
  isUnderMaintenance,
} from "@/auth/utils";
import store from "../store";

import profile from "@/router/routes/profile";
import dashboard from "./routes/dashboard";
import users from "./routes/users";
import catalog from "./routes/products";
import products from "./routes/catalog";
import authentication from "./routes/authentication";
import orders from "./routes/orders";
// import notifications from "./routes/notifications";
// import roles from "./routes/roles";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/under-maintenance",
      name: "under-maintenance",
      component: () => import("@/views/underMaintenance/index.vue"),
    },
    {
      path: "/permission-denied",
      name: "permission-denied",
      component: () => import("@/views/permissionDenied/index.vue"),
    },
    {
      path: "*",
      redirect: "error-404",
    },
    ...dashboard,
    ...users,
    ...catalog,
    ...products,
    ...authentication,
    ...profile,
    ...orders,
    // ...notifications,
    // ...roles
  ],
});

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = isUserLoggedIn();
  const isVendor = isUserVendorIsApproved();
  const vendorData = isUserVendorData();
  const isPlatFormUnderMaintenance = await isUnderMaintenance();
  if (isPlatFormUnderMaintenance && to.name !== "under-maintenance") {
    return next({ name: "under-maintenance" });
  }
  if (!canNavigate(to)) {
    if (
      (!isLoggedIn && to.name !== "login") ||
      (!isLoggedIn && to.name !== "signup")
    )
      return next({ name: "login" });
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    if (to.name === "login") {
      return next({ name: "home" });
    }
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  if (
    ![
      "login",
      "signup",
      "forget-password",
      "create-new-vendor",
      "vendor-pending",
    ].includes(to.name)
  ) {
    const storedVendorData = getVendorRoles();
    if (storedVendorData) {
      const canGo = getRoutePermissionCheck(
        to.meta.permission,
        handelPermission(storedVendorData)
      );
      if (!canGo && to.name !== "permission-denied") {
        return next({ name: "permission-denied" });
      }
    }
  }

  if (to.name === "vendor-pending") {
    if (!vendorData) {
      return next({ name: "login" });
    }
    if (isVendor) {
      return next({ name: "home" });
    }
    return next();
  }

  if (to.name === "create-new-vendor") {
    if (isVendor) {
      return next({ name: "home" });
    }
    if (!isVendor && vendorData) {
      return next({ name: "vendor-pending" });
    }
    return next();
  }

  return next();
});

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
