export default [
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/profile/index.vue"),
    meta: {
      pageTitle: "g.profile",
      permission: { vendor: { profile: true } },
      breadcrumb: [
        {
          text: "g.profile",
          active: true,
        },
      ],
    },
  },
];
