import axiosIns from "@axios";
import { handelPermission } from "@/auth/utils";

export default {
  state: {
    userId: null,
    userData: null,
    is_blocked: false,
    role: null,
    permissions: [],
    formattedPermission: {},
    sub_Permission: {},
    sub_Permissions: {},
    avatar: null,
    is_approved: false,
    vendorId: null,
  },
  getters: {
    getUserData(state) {
      return state.userId;
    },
    getUserPermission(state) {
      return state.formattedPermission;
    },
  },
  mutations: {},
  actions: {
    async FETCH_DATA_USER({ state }) {
      try {
        const res = await axiosIns.get(`auth/profile?guard=vendor`);
        if (res.status === 200) {
          const { data, user_vendor } = res.data;
          const is_approved = user_vendor.vendor.is_approved;
          const userData = { ...data };

          data.is_approved = is_approved;
          state.userData = userData;
          state.is_approved = is_approved;
          state.userId = data.id;
          state.is_blocked = data.is_blocked;
          window.localStorage.setItem("myVendorData", JSON.stringify(data));
          window.localStorage.setItem("vendorId", user_vendor.vendor.id);
          window.localStorage.setItem("userData", JSON.stringify(data));
          window.localStorage.setItem("isVendor", is_approved);

          state.formattedPermission = handelPermission(
            user_vendor?.vendor_roles?.roles?.filter(
              (ele) => ele.guard_name === "vendor"
            )[0]?.permissions
          );

          if (data.is_blocked) {
            localStorage.clear();
            window.location.href = "/";
          }
        }
      } catch (err) {}
    },

    /**
     * GET_PERMISSION({ state }, payload)
     * @param {string} payload
     * @returns {void} nothing
     */
    GET_PERMISSION: ({ state }, payload) => {
      if (typeof payload !== "string") {
        throw new Error("payload must be an string");
      }

      const permission = state.permissions.find(
        (item) => item.name === payload
      );
      permission?.permission?.sub_permissions?.forEach(
        (item) => (state.sub_Permission[item.name] = true)
      );
    },

    /**
     * GET_PERMISSIONS({ state }, payload)
     * @param {string[]} payload
     * @returns {void} nothing
     */
    GET_PERMISSIONS: ({ state }, payload) => {
      if (!Array.isArray(payload)) {
        throw new Error("payload must be an array");
      }

      const filteredPermissions = state.permissions.filter((item) =>
        payload.includes(item.name)
      );

      if (filteredPermissions.length) {
        filteredPermissions.forEach((permission) => {
          const subPermissions = {};
          for (const subPermission of permission?.permission?.sub_permissions) {
            subPermissions[subPermission.name] = true;
          }
          state.sub_Permissions[permission.name] = subPermissions;
        });
      }
    },

    avatar_change: ({ state }, payload) => {
      state.avatar = payload;
    },
  },
};
