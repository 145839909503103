export default [
  {
    path: "/show-order/:id",
    name: "show-order",
    meta: {
      permission: { orders: { show: true } },
    },
    component: () => import("@/views/orders/showOrder/index.vue"),
  },
  {
    path: "/orders-list",
    name: "orders-list",
    meta: {
      permission: { orders: { index: true } },
    },
    component: () => import("@/views/orders/ordersList/index.vue"),
  },
];
